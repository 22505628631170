@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');

* {
  box-sizing: border-box;
}

/*
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  color: #393c41;
  margin: 0;
  padding: 0;
}
*/

body {
  width: 100%;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit
}

a:visited {
  color: inherit
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #fffc;
  ;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  /*width: 100%;*/
  width: calc(100% - 20px);
  transform: scaleX(0);
  height: 2px;
  /*bottom: 0;*/
  top: calc(100% + 4px);
  left: 0;
  background-color: #fffc;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  margin: 0 10px;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

html {scroll-behavior: smooth;}

/* 
buttons 
https://devdevout.com/css/css-round-buttons
*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.buttons {
  margin: 10%;
  text-align: center;
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;

  border-radius: 5px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-9 {
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}